import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { connect } from 'react-redux';

import { patchUpdate as patchUpdateUser } from './actions/account/show';
import { list as procedureList } from './actions/procedure/list';
import { list as tagList } from './actions/tag/list';
import { list as usedProcedureList } from './actions/usedprocedure/list';
import { list as inviteList } from './actions/user/list';
import pdf from './assets/file/listaQuickStartGuide.pdf';
import blogImage from './assets/img/Lista-SOPsImportant.png';
import ProcedureList from './components/dashboard/ProcedureList';
import UsedProcedureList from './components/dashboard/UsedProcedureList';
import { VersionLink as Link } from './components/VersionLink';
import { APP_SITE_ID } from './config/entrypoint';
import { getPageWithFilterValues } from './utils/listFilter';

class Dashboard extends Component {
  TEMPLATES = [
    <MainTemplate1 />,
    <MainTemplate2 />,
    <MainTemplate3 />,
    <MainTemplate3 />
  ];

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    procedureList: PropTypes.func.isRequired,
    usedProcedureList: PropTypes.func.isRequired
  };

  getPageWithNotCompletedFilter = page => {
    let queryString =
      'completed_status.label=Incomplete&completed_status.value=incomplete';
    // let queryString = 'completed_status.value=incomplete';
    let url = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;
    return url;
  };

  componentDidMount() {
    Promise.all([
      this.props.usedProcedureList(
        this.getPageWithNotCompletedFilter(
          this.getPageWithFilterValues(
            // 'usedprocedureFilterValues',
            'disabled_usedprocedureFilterValues',
            this.props.match.params.page &&
              decodeURIComponent(this.props.match.params.page),
            '/used_procedures?order[createdAt]=desc&itemsPerPage=5'
          )
        )
      ),
      this.props.procedureList(
        this.getPageWithFilterValues(
          // 'procedureFilterValues',
          'disabled_procedureFilterValues',
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page),
          `/procedures?itemsPerPage=5&cuaid=${this.props.currentUserAccount.id}`
        )
      ),
      this.props.tagList(
        this.getPageWithFilterValues(
          'tagFilterValues',
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page),
          '/tags?itemsPerPage=1'
        )
      ),
      this.props.inviteList(
        this.getPageWithFilterValues(
          'userFilterValues',
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page),
          `/users?createdBy.id=${this.props.currentUserAccount['@id']}&itemsPerPage=1`
        )
      )
    ]);
  }

  getPageWithFilterValues = (filterValues, page, uri) => {
    return getPageWithFilterValues(filterValues, page, uri);
  };

  welcomeDescription = mainTemplate => {
    switch (mainTemplate) {
      case 1:
        return (
          <div className="col-md-7 col-lg-5 col-9 welcome-description">
            {!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
              <>
                <p>
                  Thank you for signing up, we're happy to have you on board.
                </p>
                <p>
                  Begin by clicking <strong>Use</strong> on one of the{' '}
                  {/*<strong>Templates</strong> to start a new case. This will*/}
                  {/*generate a new <strong>Active Procedure</strong>, i.e., an*/}
                  <strong>Case Workflows</strong> to start a new case. This will
                  generate a new <strong>Active Case Workflow</strong>, i.e., an
                  active case on which you are working. In the future, you will
                  {/*have different Active Procedures (based on the same Template)*/}
                  have different Active Case Workflows (based on the same Case Workflow)
                  for each of your firm's active cases.
                </p>
                <p>
                  {/*In the Active Procedures, start by <strong>tagging</strong> the*/}
                  In the Active Case Workflows, start by <strong>tagging</strong> the
                  case with the employer's name, the foreign national's name, or
                  any descriptors that will help you find it later. Then check
                  {/*off steps of the procedure as you work through your case.*/}
                  off steps of the case workflow as you work through your case.
                </p>
              </>
            ) : (
              <>
                <p>
                  {/*Next up, we'd like you to try using a procedure. This is how your{' '}*/}
                  {/*Next up, we'd like you to try using a template. This is how*/}
                  Next up, we'd like you to try using a case workflow. This is how
                  your{' '}
                  {/* Procedure Templates get turned into actionable lists, where you */}
                  {/*Templates get turned into actionable lists, where you can add*/}
                  Case Workflows get turned into actionable lists, where you can add
                  tags, set due dates, attach files, add notes, and more!
                </p>
                <p>
                  {/*Click <strong>Use</strong> next to any procedure below to get*/}
                  {/*Click <strong>Use</strong> next to any template below to get*/}
                  Click <strong>Use</strong> next to any case workflow below to get
                  started!
                </p>
              </>
            )}
          </div>
        );
      case 2:
        return <WelcomeAccordion {...this.props} />;
      case 3:
        return <></>;
      default:
        return (
          <div className="col-md-7 col-lg-5 col-9 welcome-description">
            {!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
              <>
                <p>
                  Thank you for signing up. We're happy to have you on board!
                </p>
                <p>
                  You can get started by purchasing case workflows. Case workflows are the
                  repeatable processes that your firm uses to prepare a case.
                  {/*Click on <strong>Buy Templates</strong> in the top right to*/}
                  {/*see available case templates and purchase*/}
                  Click on <strong>Buy Case Workflows</strong> in the top right to
                  see available case workflows and purchase.
                </p>
                <p>
                  {/*Once you've purchased one or more templates, come back to your*/}
                  {/*Lista Dashboard to use your first Template!*/}
                  Once you've purchased one or more case workflows, come back to your
                  Lista Dashboard to use your first Case Workflow!
                </p>
              </>
            ) : (
              <>
                <p>
                  Thank you for signing up, we're happy to have you on board.
                </p>
                <p>
                  To get you started with the project, we're going to walk you{' '}
                  {/* through how to create a <strong>Procedure Template.</strong> These */}
                  {/*through how to create a <strong>Template.</strong> These are*/}
                  through how to create a <strong>Case Workflow.</strong> These are
                  how you document your company's repeatable processes.
                </p>
              </>
            )}
          </div>
        );
    }
  };

  introVideo = mainTemplate => {
    switch (mainTemplate) {
      case 0:
        return (
          <div className="col-md-7 col-lg-7 col-9 intro-video">
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NjjEnKMH5dM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  getMainTemplate = (
    procedureTotal,
    usedProcedureTotal,
    inviteTotal,
    tagTotal,
    procedure,
    currentUserAccount
  ) => {
    const idStrings =
      !!currentUserAccount &&
      !!currentUserAccount['@_organizationCurrentSampleProcedureIds'] &&
      !!currentUserAccount['@_organizationCurrentSampleProcedureIds'].length
        ? currentUserAccount['@_organizationCurrentSampleProcedureIds'].map(
            item => item['@id']
          )
        : [];
    const compareIds =
      !!procedure &&
      !!procedure['hydra:member'] &&
      !!procedure['hydra:member'].length
        ? procedure['hydra:member'].filter(
            item => !idStrings.includes(item['@id'])
          )
        : [];

    if (Boolean(usedProcedureTotal)) {
      if (inviteTotal || tagTotal) {
        return 3;
      }
      return 2;
    }
    // Skipping the sample procedure
    // if (Boolean(procedureTotal)) {
    if (Boolean(compareIds.length)) {
      return 1;
    }
    return 0;
  };

  getTotals = (procedure, usedProcedure, invite, tag) => [
    procedure['hydra:totalItems'],
    usedProcedure['hydra:totalItems'],
    invite['hydra:totalItems'],
    tag['hydra:totalItems']
  ];

  render() {
    const {
      loading,
      error,
      procedure,
      usedProcedure,
      invite,
      tag,
      currentUserAccount
    } = this.props;

    if (loading || !procedure || !usedProcedure || !invite || !tag) {
      return (
        <div className="dashboard" id="lista-dashboard">
          <div className="jumbotron-fluid welcome-container">
            <div className="welcome-message d-flex">
              <h1 className="skeleton skeleton-h1" style={{ width: 400 }}></h1>
            </div>
          </div>

          <div className="col-md-7 col-lg-5 col-9">
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-text"></div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="main-section template-wrapper">
                <h2 className="col-header skeleton skeleton-h1"></h2>
                <div className="row">
                  <div className="welcome-description col-description col-9  ">
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main-section template-wrapper">
                <h2 className="col-header skeleton skeleton-h1"></h2>
                <div className="row">
                  <div className="welcome-description col-description col-9  ">
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return <h1>ERROR!</h1>;
    }

    const [procedureTotal, usedProcedureTotal, inviteTotal, tagTotal] =
      this.getTotals(procedure, usedProcedure, invite, tag);
    const mainTemplate = this.getMainTemplate(
      procedureTotal,
      usedProcedureTotal,
      inviteTotal,
      tagTotal,
      procedure,
      currentUserAccount
    );

    let dashClasses = 'dashboard';
    if (mainTemplate === 0) {
      dashClasses = 'dashboard onboarding-step-1';
    } else if (mainTemplate === 1) {
      dashClasses = 'dashboard onboarding-step-2';
    }

    return (
      <div className={dashClasses} id="lista-dashboard">
        <div className="jumbotron-fluid welcome-container">
          <div className="welcome-message d-flex">
            <h1>
              Welcome to <span className="blue">Lista</span>!
            </h1>
            <div className="bulb"></div>
          </div>

          {mainTemplate === 0 ? (
            <div className="row welcome-description-row">
              {this.welcomeDescription(mainTemplate)}
              {this.introVideo(mainTemplate)}
            </div>
          ) : mainTemplate === 1 &&
            !!APP_SITE_ID &&
            'immigration' === APP_SITE_ID ? (
            <div className="row welcome-description-row">
              {this.welcomeDescription(mainTemplate)}
              {this.introVideo(0)}
            </div>
          ) : (
            this.welcomeDescription(mainTemplate)
          )}
        </div>

        {React.cloneElement(this.TEMPLATES[mainTemplate], {
          ...this.props
        })}
        {mainTemplate === 3 &&
          (!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
            <ImmigrationResourcesComponent />
          ) : (
            <ResourcesComponent />
          ))}
      </div>
    );
  }
}

function ResourcesComponent() {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="main-section template-wrapper resources-wrapper blog-card">
          <div className={'left-one-third'}>
            <img src={blogImage} className={'lista-responsive-image'} />
          </div>
          <div className={'right-two-thirds'}>
            <h2 className="col-header">Why SOPs Are So Important</h2>
            <div className="welcome-description col-description">
              <a
                href="https://www.lista.io/why-sops-are-so-important/"
                className="btn-resource"
                target={'_blank'}
              >
                Read More
                <span
                  className="fa fa-arrow-right resource-icon"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="main-section resources-wrapper">
          <h2 className="col-header">Make the Most of Lista</h2>

          <div className="welcome-description col-description">
            <ActionBlurbIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

function ImmigrationResourcesComponent() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="main-section resources-wrapper">
          <h2 className="col-header">Make the Most of Lista</h2>

          <div className="welcome-description col-description">
            <ImmigrationActionBlurbIcon grid={4} minHeight="min-height" />
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionBlurbIcon({ grid = '12', minHeight = '' }) {
  return (
    <div className="row">
      {!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
        <div className={`col-lg-${grid}`}>
          <div className="action-info-col d-flex">
            <div className="action-info-icon invite-icon"></div>
            <div>
              <div className={`action-info ${minHeight}`}>
                <div className="action-info-content">
                  <p>
                    Invite your team to Lista and grant different permission{' '}
                    {/* levels for who can use, edit, and create procedures. */}
                    {/*levels for who can use, edit, and create templates.*/}
                    levels for who can use, edit, and create case workflows.
                  </p>
                </div>
              </div>

              <Link to="/invite" className="btn btn-primary action-info-btn">
                Invite a Teammate
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={`col-lg-${grid}`}>
          <div className="action-info-col d-flex">
            <div className="action-info-icon invite-icon"></div>
            <div>
              <div className={`action-info ${minHeight}`}>
                <div className="action-info-content">
                  <p>
                    Invite your team to Lista and grant different permission{' '}
                    {/* levels for who can use, edit, and create procedures. */}
                    {/*levels for who can use, edit, and create templates.*/}
                    levels for who can use, edit, and create case workflows.
                  </p>
                </div>
              </div>

              <Link to="/invite" className="btn btn-primary action-info-btn">
                Invite a Teammate
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className={`col-lg-${grid}`}>
        <div className="action-info-col d-flex">
          <div className="action-info-icon tag-icon"></div>
          {!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
            <div>
              <div className={`action-info ${minHeight}`}>
                <div className="action-info-content">
                  <p>
                    {/*<strong>Tag</strong> active procedures to make them easy to*/}
                    <strong>Tag</strong> active case workflows to make them easy to
                    find later. You may want to tag with an employer's name, the
                    foreign national's name, etc.
                  </p>
                </div>
              </div>
              <a
                href={'https://www.lista.io/product-faqs/?Display_FAQ=577'}
                className="btn btn-primary action-info-btn"
                target={'_blank'}
                rel="noopener noreferrer"
              >
                How to Tag
              </a>
            </div>
          ) : (
            <div>
              <div className={`action-info ${minHeight}`}>
                <div className="action-info-content">
                  <p>
                    {/*Tag an active procedure with a project or client name to*/}
                    Tag an active case workflow with a project or client name to
                    make it easy to find later.
                  </p>
                </div>
              </div>

              <Link
                to="/tags/create"
                className="btn btn-primary action-info-btn"
              >
                Create Tags
              </Link>
            </div>
          )}
        </div>
      </div>

      {!!APP_SITE_ID && 'immigration' === APP_SITE_ID ? <></> : <></>}
      <div className={`col-lg-${grid}`}>
        <div className="action-info-col d-flex">
          <div className="action-info-icon guide-icon"></div>
          <div>
            <div className={`action-info ${minHeight}`}>
              <div className="action-info-content">
                <p>
                  Ready to get to know everything Lista has to offer? Dig into
                  the FAQ.
                </p>
              </div>
            </div>
            <div>
              {/* <a
                href={pdf}
                className="btn btn-primary action-info-btn"
                target={'_blank'}
                rel="noopener noreferrer"
              >
                Read User Guide
              </a> */}
              <a
                href={'https://www.lista.io/product-faqs/'}
                className="btn btn-primary action-info-btn"
                target={'_blank'}
                rel="noopener noreferrer"
              >
                Read FAQ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ImmigrationActionBlurbIcon({ grid = '12', minHeight = '' }) {
  return (
    <div className="row">
      <div className={`col-lg-${grid}`}>
        <div className="action-info-col d-flex">
          <div className="action-info-icon invite-icon"></div>
          <div>
            <div className={`action-info ${minHeight}`}>
              <div className="action-info-content">
                <p>
                  Invite your team to Lista and grant different permission{' '}
                  {/* levels for who can use, edit, and create procedures. */}
                  {/*levels for who can use, edit, and create templates.*/}
                  levels for who can use, edit, and create case workflows.
                </p>
              </div>
            </div>
            <a
              href={'https://www.lista.io/product-faqs/?Display_FAQ=581'}
              className="btn btn-primary action-info-btn"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Invite a Teammate
            </a>
          </div>
        </div>
      </div>
      <div className={`col-lg-${grid}`}>
        <div className="action-info-col d-flex">
          <div className="action-info-icon tag-icon"></div>
          <div>
            <div className={`action-info ${minHeight}`}>
              <div className="action-info-content">
                <p>
                  {/*<strong>Tag</strong> active procedures to make them easy to*/}
                  <strong>Tag</strong> active case workflows to make them easy to
                  find later. You may want to tag with an employer's name, the
                  foreign national's name, etc.
                </p>
              </div>
            </div>
            <a
              href={'https://www.lista.io/product-faqs/?Display_FAQ=577'}
              className="btn btn-primary action-info-btn"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              How to Tag
            </a>
          </div>
        </div>
      </div>
      <div className={`col-lg-${grid}`}>
        <div className="action-info-col d-flex">
          <div className="action-info-icon guide-icon"></div>
          <div>
            <div className={`action-info ${minHeight}`}>
              <div className="action-info-content">
                <p>
                  Ready to get to know everything Lista has to offer? Dig into
                  the FAQ.
                </p>
              </div>
            </div>
            <div>
              {/* <a
                href={pdf}
                className="btn btn-primary action-info-btn"
                target={'_blank'}
                rel="noopener noreferrer"
              >
                Read User Guide
              </a> */}
              <a
                href={'https://www.lista.io/product-faqs/'}
                className="btn btn-primary action-info-btn"
                target={'_blank'}
                rel="noopener noreferrer"
              >
                Read FAQ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WelcomeAccordion({ currentUserAccount, patchUpdateUser }) {
  const [loading, setLoading] = useState(false);
  let isContinueOnboardingDisabled = Boolean(
    currentUserAccount.isContinueOnboardingDisabled
  );

  function CustomToggle({ eventKey }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      setLoading(true);
      const newIsContinueOnboardingDisabled = !isContinueOnboardingDisabled;
      const patchUpdateUserPromise = patchUpdateUser(currentUserAccount, {
        isContinueOnboardingDisabled: newIsContinueOnboardingDisabled
      });
      patchUpdateUserPromise.then(({ type, updated }) => {
        if (type === 'ACCOUNT_SHOW_SUCCESS') {
          setLoading(false);
        }
      });
    });

    if (loading) {
      return (
        <div className="custom-toggle">
          <button type="button" className="custom-toggle-btn" disabled>
            <span className="fa fa-spinner fa-xs" aria-hidden="true" /> Loading
          </button>
        </div>
      );
    }

    return (
      <div className="custom-toggle">
        <button
          type="button"
          className="custom-toggle-btn"
          onClick={decoratedOnClick}
        >
          {isContinueOnboardingDisabled ? (
            <>
              show{' '}
              <span className="fa fa-chevron-down fa-xs" aria-hidden="true" />
            </>
          ) : (
            <>
              hide{' '}
              <span className="fa fa-chevron-up fa-xs" aria-hidden="true" />
            </>
          )}
        </button>
      </div>
    );
  }

  return (
    <Accordion defaultActiveKey={String(Number(isContinueOnboardingDisabled))}>
      <CustomToggle eventKey="0" />
      {!loading && (
        <Accordion.Collapse eventKey="0">
          <div className="main-section template-welcome-wrapper">
            <div className="row">
              <div className="col-12">
                <h2>Make the Most of Lista</h2>
              </div>
            </div>
            <ActionBlurbIcon grid={4} minHeight="min-height" />
          </div>
        </Accordion.Collapse>
      )}
    </Accordion>
  );
}

function MainTemplate1({ procedure, currentUserAccount }) {
  return !!APP_SITE_ID && 'immigration' === APP_SITE_ID ? (
    <></>
  ) : (
    <div className="main-section template-wrapper">
      {/* <h2 className="text-center">Procedure Templates</h2> */}
      <div className="d-flex justify-content-center">
        <div className="list-icon procedure-list-icon"></div>
        {/* <h2 className="col-header">Procedure Templates</h2> */}
        {/*<h2 className="col-header">Templates</h2>*/}
        <h2 className="col-header">My Workflow Library</h2>
      </div>

      <div className="row">
        <div className="col-lg-6 col-custom">
          <div className="col-item">
            {!!currentUserAccount['@_organizationCurrentSampleProcedureIds'] &&
            !!currentUserAccount['@_organizationCurrentSampleProcedureIds']
              .length &&
            currentUserAccount['@_organizationCurrentSampleProcedureIds'][0] &&
            currentUserAccount['@_organizationCurrentSampleProcedureIds'][0][
              '@id'
            ] ? (
              <Link
                to={`/procedures/edit/${encodeURIComponent(
                  currentUserAccount[
                    '@_organizationCurrentSampleProcedureIds'
                  ][0]['@id']
                )}`}
                className="btn btn-primary see-an-example"
              >
                See an Example First
              </Link>
            ) : !!procedure['hydra:member'].length &&
              procedure['hydra:member'][0] &&
              procedure['hydra:member'][0]['@id'] ? (
              <Link
                to={`/procedures/edit/${encodeURIComponent(
                  procedure['hydra:member'][0]['@id']
                )}`}
                className="btn btn-primary see-an-example"
              >
                See an Example First
              </Link>
            ) : (
              <div className="btn btn-primary see-an-example">
                See an Example First
              </div>
            )}
          </div>
          <div className="action-help">
            <div className="action-help-content">
              <p>
                {/* Not sure yet where to start? Try editing a sample procedure */}
                {/* Not sure yet where to start? Try editing a sample template */}
                Not sure yet where to start? Try editing a sample case workflow
                before creating your own.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-custom">
          <div className="col-item">
            <Link
              to="/procedures/create"
              className="btn btn-primary my-first-procedure"
            >
              {/* Create My First Procedure */}
              {/*Create My First Template*/}
              Create My First Case Workflow
            </Link>
          </div>

          <div className="action-help">
            <div className="action-help-content">
              <p>
                Already have an idea in mind for a process that your company{' '}
                {/* uses? Get started with creating your own procedure template! */}
                {/*uses? Get started with creating your own template! Everything is*/}
                uses? Get started with creating your own case workflow! Everything is
                editable later on.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MainTemplate2({ procedure, history }) {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="main-section template-wrapper">
          <div className="d-flex">
            <div className="list-icon used-procedure-list-icon"></div>
            <h2 id="procedure-in-use" className="col-header">
              {/* Procedures in Use */}
              {/*Active Procedures*/}
              Active Case Workflows
            </h2>
          </div>
          <div className="row">
            <div className="welcome-description col-description col-9">
              <p>
                {/* You have no procedures in use yet! Click <strong>Use</strong> on
                the right to begin using a procedure template. */}
                {/*You have no active procedures yet! Click <strong>Use</strong> on*/}
                {/*the right to begin using a template.*/}
                You have no active case workflows yet! Click <strong>Use</strong> on
                the right to begin using a case workflow.
              </p>
            </div>
            <div className="col-3 arrow-right-wrapper">
              <span className="fa fa-arrow-right" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <ProcedureList procedure={procedure} history={history} />
      </div>
    </div>
  );
}

function MainTemplate3({
  procedure,
  usedProcedure,
  history,
  currentUserAccount
}) {
  return (
    <div className="row">
      <div className="col-lg-6">
        <UsedProcedureList usedProcedure={usedProcedure} />
      </div>
      <div className="col-lg-6">
        <ProcedureList procedure={procedure} history={history} />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { retrieved, loading, error } = state.procedure.list;
  const {
    retrieved: upRetrieved,
    loading: upLoading,
    error: upError
  } = state.usedprocedure.list;
  const {
    retrieved: tagRetrieved,
    loading: tagLoading,
    error: tagError
  } = state.tag.list;
  const {
    retrieved: inviteRetrieved,
    loading: inviteLoading,
    error: inviteError
  } = state.user.list;

  return {
    currentUserAccount: state.account.show.retrieved,
    procedureCloned: state.procedure.clone.cloned,
    procedure: retrieved,
    loading: loading || upLoading || tagLoading || inviteLoading,
    error: error || upError || tagError || inviteError,
    usedProcedure: upRetrieved,
    tag: tagRetrieved,

    invite: inviteRetrieved
  };
};

const mapDispatchToProps = dispatch => ({
  procedureList: page => dispatch(procedureList(page)),
  usedProcedureList: page => dispatch(usedProcedureList(page)),
  tagList: page => dispatch(tagList(page)),
  inviteList: page => dispatch(inviteList(page)),
  patchUpdateUser: (item, values) => dispatch(patchUpdateUser(item, values))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
